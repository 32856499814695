@import '~antd/dist/antd.css';
@import './variables';
@import './fonts';

body {
  font-family: 'Barlow', sans-serif;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $font-secondary;
  font-family: Barlow;
  font-weight: 200;
}

h1 {
  color: $font-primary;
  font-weight: 700;
  font-size: 40px;
  @media only screen and (max-width: $sm) {
    font-size: 30px;
  }
}

h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
}

h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 25px;
  color: $tertiary;
}

p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  @media only screen and (max-width: $sm) {
    font-size: 13px;
    line-height: 160%;
  }
}

a {
  color: $font-primary;
}
// Ant Design Components
/* stylelint-disable */

.ant-switch-checked{
  background-color: $primary;
}

/* stylelint-enable */
