@import '../../../assets/globalStyles/index.scss';

.tableRowLight {
  background-color: $row-light;
  :global {
    .ant-table-cell-row-hover {
      background-color: $row-hover !important;
    }
  }
}
.tableRowDark {
  background-color: $row-dark;
  :global {
    .ant-table-cell-row-hover {
      background-color: $row-hover !important;
    }
  }
}