@import 'assets/globalStyles/variables';

.Title {
  color: $tertiary;
}

.Row {
  margin-bottom: 2em;
}

.Tag {
  margin-bottom: 4px;
  background-color: $dark-primary;
  color: $font-secondary;
  &:last-child {
    margin-bottom: 0;
  }
}