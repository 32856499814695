@import 'assets/globalStyles/variables.scss';

.Title {
  color: $tertiary;
}

.Row {
  margin-bottom: 2em;
}

.FullWidth {
  width: 100%;
}

.Switch {
  text-align: center;
}

.MarginBottom {
  margin-bottom: 1em;
}