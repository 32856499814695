@import '../../../../assets/globalStyles/variables.scss';

.Text {
  text-align: start;
}

.Layout {
  padding: 20px 10px;
  background-color: $disabled-light-gray;
}

.Column {
  min-width: fit-content;
}