@import 'assets/globalStyles/variables';

.Title {
  color: $tertiary;
}

.Row {
  margin-bottom: 2em;
}

.Description {
  p {
    color: rgba(0, 0, 0, 0.85);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    font-size: 14px;
  }
}

