@import 'assets/globalStyles/variables.scss';

.Card {
  border-color: $disabled-light-gray;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.Title {
  color: $tertiary;
  margin: 0;
}

.SmallTitle {
  color: $tertiary;
  margin: 0;
  font-size: xx-large;
  margin-left: 5px;
}

.Icon {
  font-size: xx-large;
  margin-right: 5px;
  margin-top: 5px;
}

.CountContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProgressBar{
  margin: 2em 0;
}

.IconImage{
  width: 3em;
}
