@import 'assets/globalStyles/variables'; 

.Container {
  padding-right: 3%;
  padding-top: 10px;
  padding-left: 3%;
  background-color: $row-light;
  :global {
    .ant-tabs-tab .ant-tabs-tab-active .ant-tabs-tab-btn 
    {
      color: $primary;
    }
    .ant-tabs-tab:hover {
      color: $primary;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $primary;
    }
    .ant-tabs-ink-bar {
      position: absolute;
      background: $primary;
      pointer-events: none;
    }
    .ant-tabs-nav {
      margin-bottom: 10px;
    }
  }
}
