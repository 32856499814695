/* stylelint-disable selector-max-compound-selectors */
/* stylelint-disable selector-class-pattern */
@import 'assets/globalStyles/variables';

.Container {
  height: 100%;
  min-height: 100vh;
  width: 100%;

  :global {
    .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
      color: $primary !important;
    }

    .ant-menu.ant-menu-dark {
      background-color: $tertiary;
    }

    .ant-menu-inline.ant-menu-sub {
      background-color: $dark-tertiary;
    }

    .ant-menu.ant-menu-dark .ant-menu-item-selected {
      background-color: $secondary !important;
    }

    .ant-menu-item:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}

.Menu {
  height: 100%;
  padding: 15px 0;
}
