@import '../../../assets/globalStyles/variables';

.Title {
  color: $tertiary;
}

.Row {
  margin-bottom: 2em;
}

.CoverRecommendation {
  color: $alert-orange;
}