@import '../../../../assets/globalStyles/variables.scss';

.OrderDetailContainer {
  text-align: start;
}

.Layout {
  width: 100%;
  padding: 1.5rem;
}

.Table {
  width: 100%;
}

.Disabled {
  background-color: $row-dark;
  :global {
    .ant-table-thead{
      .ant-table-cell {
        color: $row-dark !important;
        background-color: $row-light !important;
      }
    }
    .ant-empty-normal {
      visibility: hidden !important;
    }
    .ant-table-cell{
      background-color: $row-light !important;
    }
  }
}

.CreatedAt {
  margin-bottom: 0;
}

.GiftCardAmountUsed {
  margin-bottom: 0;
}
