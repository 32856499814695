.CustomRow {
  :global{
    .ant-table-cell {
      padding: 0px 16px;
    }
    .ant-form-item-control {
      margin-top: 20px;
    }
  }
}

.FooterButtons {
  margin-top: 20px;
}

.ModalTitle {
  :global {
    .ant-modal-title {
      font-weight: 500;
      font-size: 24px;
      line-height: 25px;
    }
  }
}