.NoButton {
  display: none;
}

.MarginTop {
  margin-top: 20px;
}

.TextEnd {
  text-align: end;
}

.Modal {
  width: 800px !important;
}
