@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');

@font-face {
  font-family: Barlow;
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Barlow/Barlow-Light.otf');
}

@font-face {
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Barlow/Barlow-Regular.otf');
}

@font-face {
  font-family: Barlow;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Barlow/Barlow-Medium.otf');
}

@font-face {
  font-family: Barlow;
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/Barlow/Barlow-Italic.otf');
}

@font-face {
  font-family: Barlow;
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/Barlow/Barlow-BoldItalic.otf');
}

@font-face {
  font-family: Barlow;
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Barlow/Barlow-Bold.otf');
}

@font-face {
  font-family: Barlow;
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/Barlow/Barlow-Black.otf');
}
