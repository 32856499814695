.OptionsTable {
  width: 100%;
  margin-bottom: 8vh;
  :global {
    .ant-table-thead > tr > th {
      background-color: white;
    }
    .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none;
    }
  }
}

.Input {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  width: 100%;
}

.NotRoundInput {
  width: 100%;
}

.Row {
  width: 70%;
  margin-bottom: 5vh;
}

.Center {
  justify-content: space-around;
}