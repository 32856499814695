@import 'assets/globalStyles/variables.scss';

.Container {
  background: $primary;
  height: 64px;
  text-align: center;
  width: 100%;
}

.Logo {
  height: 40px;
  margin: auto;
  position: relative;
  width: auto;
}
