/* stylelint-disable function-parentheses-space-inside */

// COLORS
$primary: #21b367;
$dark-primary: #016433;
$pale-primary: #c1e5c4;
$secondary: #f26d31;

$pale-secondary: #7cd8da92;
$vector-light-blue: #3edbde;
$background: #ffff;
$tertiary: #1e2027;
$dark-tertiary: #0c0d15;
$font-primary: #21b367;
$font-secondary: white;
$font-dark-blue: #364c84;

// GRAY SCALE
$dark-gray: #333;
$gray2: #4f4f4f;
$gray3: #828282;
$disabled-gray: #909090;
$disabled-light-gray: #e5e5e5;
$blocked-gray: #90909080;
$disabled-gray-background: #f0f0f0;
$pale-gray: #f2f2f2;

// ALERTS
$error-red: #eb5757;
$alert-orange: #f2994a;
$alert-yellow: #f2c94c;
$succcess-green: #4bb543;

// GRADIENTS
$gradient-primary-02: #9fb1ea;
$gradient-secundary-02: #7cd8da;
$gradient-secundary-03: #c5feff;

// STATES
$not-active: #c2370b;

// TABLES
$row-light: #f4f4f4;
$row-dark: #e7e7e7;
$row-hover: #c6c6c6;

$primary-gradient: linear-gradient(
  261.49deg,
  $primary 30.02%,
  $gradient-primary-02 126.62%
);
$secondary-gradient: linear-gradient(
  261.49deg,
  $secondary 30.02%,
  $gradient-secundary-02 112.03%,
  $gradient-secundary-03 126.62%
);

// BREAKPOINTS
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

// MEASURES
$navbar-height: 56px;
$tab-bar-height: 66px;
$main-padding-top: 40px;
$main-padding-right: 10px;
$main-padding-bottom: 40px;
