@import '../../../assets/globalStyles/index.scss';

.ModalTitle {
  :global {
    .ant-modal-title {
      font-weight: 500;
      font-size: 24px;
      line-height: 25px;
      color: $tertiary;
    }
  }
}