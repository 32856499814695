@import '../../assets/globalStyles/variables';

.Container {
  &:hover {
    background-color: $pale-gray;
  }
}

.TitleContainer {
  text-align: left;
  h2 {
    color: $dark-tertiary;
  }
}

.Calendar {
  :global {
    .ant-picker-calendar-mode-switch{
      display: none;
    }
  }
}