@import 'assets/globalStyles/variables';

.Title {
  color: $tertiary;
}

.Row {
  margin-bottom: 2em;
}

.CountContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.SmallIcon {
  font-size: 20px;
}

.FormItem {
  :global {
    .ant-form-item-control-input {
      text-align: center;
    }
    .ant-input-number-affix-wrapper {
      padding: 10px;
      width: fit-content;
    }
  }
}

.Input {
  :global {
    .ant-input-number-input {
      width: 100%;
      margin: 0px;
      text-align: center;
      font-size: 30px;
    }
  }
}

