@import 'assets/globalStyles/variables.scss';

.PrimaryButton,
.PrimaryButton:hover {
  background-color: $secondary;
  color: $background;
  border-color: $secondary;
  width: fit-content;
}
.PrimaryButton:focus {
  border-color: $secondary;
  color: $secondary;
}

.ActionButton, .ActionButton:hover {
  background-color: $secondary;
  color: $background;
  border-color: $secondary;
  width: 100%;
}

.CancelButton, .CancelButton:hover {
  background-color: $secondary;
  color: $background;
  border-color: $secondary;
}