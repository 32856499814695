@import 'assets/globalStyles/variables.scss';

.modal {
  :global {
    .ant-modal-content{
      background-color: transparent;
      box-shadow: none;
    }
    .ant-modal-body{
      text-align: center;
    }
  }
  .Loader {
    font-size: 125px;
    @media only screen and (max-width: $md) {
      font-size: 75px;
    }
  }
  p {
    color: $background;
    text-align: center;
    margin-top: 20px !important;
  }
}
