@import '../../../../assets/globalStyles/variables.scss';

.Text {
  text-align: start;
  margin-bottom: 24px !important;
}

.Layout {
  margin-bottom: 15px;
  padding: 1.5rem;
  background-color: $disabled-light-gray;
}

.Column {
  min-width: fit-content;
}