@import 'assets/globalStyles/variables';

.MainContainer {
  background-color: $background;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  width: 100vw;
  
  &::-webkit-scrollbar {
    display: none;
  }
}

.App {
  margin-left: 10%;
  width: 80%;

  @media only screen and (max-width: $lg) {
    margin-left: 2.5%;
    width: 95%;
  }
}

.ComponentContainer {
  max-height: calc(100vh - $navbar-height);
  overflow-y: scroll;
  padding: 25px 3%;
  width: 100%;
}

.FullHeight {
  height: 100%;
}
