@import 'assets/globalStyles/breakpoints';

.Row {
 :global {
   .ant-col-6 {
     max-width: 20%;
   }
 }
}

.Input {
  height: 34px;
  width: 100%;
}