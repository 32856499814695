@import 'assets/globalStyles/variables.scss';

.Title {
  color: $tertiary;
}

.Row {
  margin-bottom: 2em;
}

