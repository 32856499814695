@import '../../assets/globalStyles/variables';

.DecorationBar {
  background: $error-red;
  height: 10px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.ErrorContainer {
  width: 100%;
}

.ErrorModal {
  color: $font-dark-blue;
  font-family: 'Open Sans';
  font-weight: 300;
  height: 90% !important;
  width: 90% !important;

  @media screen and (min-height: $xl) {
    height: 70%;
    margin-bottom: auto;
    margin-top: auto;
  }

  @media screen and (min-width: $xl) {
    margin-left: auto;
    margin-right: auto;
    width: calc(100vw / 2 + 100px) !important;
  }

  .TitleContainer {
    border-bottom: dashed 1px $font-dark-blue;
    margin: 50px auto 0;
    padding-bottom: 50px;
    width: 80%;

    @media only screen and (max-width: $md) {
      margin-bottom: 10px;
      padding-bottom: 10px;
      width: 90%;
    }
  }

  .ErrorIcon {
    background: $error-red;
    border-radius: 50%;
    color: white;
    display: inline-block;
    font-family: 'Open Sans';
    font-size: 30px;
    font-weight: 700;
    height: 45px;
    text-align: center;
    width: 45px;

    @media only screen and (max-width: $md) {
      margin: 15px auto;
    }
  }

  .ModalTitle {
    font-family: 'Gotham';
    font-size: 18px;
    font-weight: 400;
  }

  .ModalSubTitle {
    font-family: 'Gotham';
    font-size: 14px;
    font-weight: 200;
  }

  .ErrorInfo {
    border-bottom: dashed 1px $font-dark-blue;
    font-weight: 700;
    margin: 50px auto 0;
    padding-bottom: 50px;
    width: 80%;

    @media only screen and (max-width: $md) {
      margin: 5px auto 10px;
      padding-bottom: 10px;
      width: 90%;
    }
  }

  .ErrorMessageContainer {
    margin: 10px auto;
    width: 100%;

    .ErrorCode {
      font-weight: 400;
    }
  }

  .Contact {
    margin: 50px auto 0;
    white-space: pre-wrap;
    width: 80%;

    @media only screen and (max-width: $md) {
      padding-bottom: 10px;
      width: 90%;
    }

    .ContactParagraph {
      text-align: justify;
    }

    .ContactBold {
      color: $font-dark-blue;
      font-weight: 700;
    }
  }

  .ButtonContainer {
    margin: 20px auto 50px;
    width: 50%;
  }
}
