@import '../../../assets/globalStyles/variables';

.Title {
  color: $tertiary;
}

.Row {
  margin-bottom: 2em;
}

.ButtonContainer {
  margin-bottom: 1vh;
}

.Modal {
  width: 80vw !important;
}

.ModalTitle {
  color: $tertiary;
  font-size: 24px;
  font-weight: 500;
  line-height: 25px;
}
