@import 'assets/globalStyles/variables';

.Title {
  color: $tertiary;
  text-align: left;
}

.Pagination {
  width: 100%;
  text-align: right;
  padding-block: 1rem;
  padding-inline: 0.2rem;
  color: $primary;
  :global {
    .ant-select, .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      padding: 0 7px;
      font-size: 15px;
    }
  }
}